import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Instence from "../../utils/Instence";
import Slider from "react-slick";
import propImg from "../../Img/team/property.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ProductSliderV1 = ({ userId }) => {
  console.log(userId);
  const [images, setImages] = useState([]);
  const [userdata, setUserData] = useState("");

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Instence.get(
          `/Property/GetByPropIdOnlyTrue?Id=${userId}`
        );
        console.log("Single product", response.data);
        setUserData(response.data);
        setImages(response.data.propertyImages);
        // Handle response data as needed
      } catch (error) {
        console.error("Error fetching single product data:", error);
      }
    };

    fetchData();
  }, [userId]);

  // const imageUrls = [
  //   "assets/img/img-slide/31.jpg",
  //   "assets/img/img-slide/32.jpg",
  //   "assets/img/img-slide/33.jpg",
  //   "assets/img/img-slide/34.jpg",
  // ];

  let publicUrl = process.env.PUBLIC_URL + "/";
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
  };

  return (
    <div className="ltn__img-slider-area mb-90">
      <div className="container-fluid">
        <div className="ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
          {images.length > 1 ? (
            <Slider {...settings}>
              {images.map((property, index) => (
                <div
                  className="container-fluid px-3"
                  key={index}
                  style={{ display: "flex" }}
                >
                  <div className="ltn__img-slide-item-4 pl-3">
                    <img
                      src={`https://way2landmark20250203151009.azurewebsites.net/uploads/${property.imageName}`}
                      alt="Ramana"
                      className="slider_iMages"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="container p-2" style={{ display: "flex" }}>
              <div className="ltn__img-slide-item-4 pl-3">
                {images.length === 1 ? (
                  <img
                    src={`https://way2landmark20250203151009.azurewebsites.net/uploads/${images[0].imageName}`}
                    alt="Ramana"
                  />
                ) : (
                  <img src={propImg} alt="Alternative Image" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSliderV1;

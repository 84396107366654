import React, { Component, useEffect, useState, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import AddListing, { SpinnerComponent } from "../section-components/add-listing";
import Instence from "../../utils/Instence";
import dummyProperty from "../../Img/team/properties.jpg";
import EditListing from "../section-components/EditListing";
import { UserContext } from "../../utils/Auth";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import { Pagination } from "react-bootstrap";
import Data from "../../Data";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { FcAddImage } from "react-icons/fc";
import ContactForm from "../section-components/contact-form";
const MyAccount = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [userdata, setUserData] = useState([]);
  const [agentData, setAgentData] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profilePic, setProfilePic] = useState(null); // For handling file upload
  const [sqftPrice, setSqftPrice] = useState(null);

  const history = useHistory();
  const { logoutUser } = useContext(UserContext);

  const handlelogout = () => {
    logoutUser();
    history.push("/");
    window.location.reload();
  };



  const [ismodel, setModel] = useState(false)

  const userId = JSON.parse(sessionStorage.getItem("userData"));

  const id = userId.id


  const fetchData = async () => {
    try {
      const response = await Instence.get(`/Property/GetByUserIdOnlyTrue?id=${id}`);
      console.log(response.data);
      setUserData(response.data);
      // Handle response data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [isEditOpen, setEditOpen] = useState(false);

  const toggleEdit = (id) => {
    setEditOpen(!isEditOpen);
  };

  const [editingProperty, setEditingProperty] = useState(null);

  // ! handle agent data function


  const fetchAgentData = async () => {
    try {
      const response = await Instence.get(`/User/${id}`);
      console.log("Agent Data", response.data);
      setAgentData(response.data);
      setFirstName(response.data.firstName)
      setLastName(response.data.lastName)
      setEmail(response.data.email)
      setPassword(response.data.password)
    } catch (error) {
      console.error("Error fetching Agent data:", error);
    }
  };
  useEffect(() => {
    fetchAgentData();
  }, []);

  //! Handle delete Function
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await Instence.post(`Property/delete/${id}`);
          if (response.status === 200) {
            console.log("Property deleted successfully!");
            fetchData();
          }
        } catch (error) {
          console.log("Error deleting property", error);
        }
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  // ! edit userDetails

  const handlesaveProfile = async () => {
    try {
      const formData = new FormData();
      formData.append('Id', id);
      formData.append('FirstName', firstName);
      formData.append('LastName', lastName);
      formData.append('Email', email);
      formData.append('Password', password);
      formData.append('Image', profilePic); // Append the file to FormData

      const response = await Instence.post(`User/userEdit/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      if (response.status === 200) {
        setModel(false)
        fetchAgentData()
        Swal.fire({
          icon: "success",
          title: "Profile Updated Successfully!",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      // Handle success
      console.log('Data updated successfully:', response.data);
    } catch (error) {
      // Handle error
      console.error('Error updating data:', error);
    }
  };


  // ********************************** Pagination Logic Here *************************************************

  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(2);
  // Logic to calculate current properties to display based on pagination
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = userdata.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );
  // Logic to handle pagination page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Logic to generate pagination items
  const paginationItems = [];
  for (
    let number = Math.max(1, currentPage - 2);
    number <=
    Math.min(currentPage + 2, Math.ceil(userdata.length / propertiesPerPage));
    number++
  ) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => paginate(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  //! Add ellipsis to the pagination if necessary
  if (currentPage > 3) {
    paginationItems.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
  }
  if (currentPage < Math.ceil(userdata.length / propertiesPerPage) - 2) {
    paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
  }
  // **********************************************************************************************************
  const handleEdit = async (propertyId) => {
    console.log(propertyId);
    setEditOpen(!isEditOpen);
    try {
      const response = await Instence.get(
        `/Property/GetByPropIdOnlyTrue?Id=${propertyId}`
      ); // Adjust URL as per your backend API
      setEditingProperty(response.data);
    } catch (error) {
      console.error("Error fetching property data:", error);
    }
  };

  // useEffect(() => {
  //   console.log(editingProperty);
  // }, [editingProperty]);

  //! edit functionality

  const [category, setCategory] = useState("");
  const [showSellDetails, setShowSellDetails] = useState(false);
  const [showRentDetails, setShowRentDetails] = useState(false);
  const [propertyType, setPropertyType] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [subLocation, setSubLocation] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [propertyState, setPropertyState] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");
  const [maintanenceCharge, setMaintanenceCharge] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [TotalArea, setTotalArea] = useState("");
  const [widthOfFacingRoad, setWidthOfFacingRoad] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [bhk, setBhk] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [furnishType, setFurnishType] = useState("");
  const [covered2WheelParking, setCovered2WheelParking] = useState("");
  const [covered4WheelParking, setCovered4WheelParking] = useState("");
  const [open2WheelParking, setOpen2WheelParking] = useState("");
  const [open4WheelParking, setOpen4WheelParking] = useState("");
  const [builtupArea, setBuiltupArea] = useState("");
  const [Facing, setFacing] = useState("");
  const [carpetArea, setCarpetArea] = useState("");
  const [activestatus, setactiveStatus] = useState(false);
  const [processionStatus, setProcessionStatus] = useState("");
  const [GpsLocationLink, setGpsLocationLink] = useState("");

  console.log(showSellDetails);

  useEffect(() => {
    // Populate form fields with editingProperty data when it is available
    if (editingProperty) {

      setGpsLocationLink(editingProperty.gpslocation);
      // setGpsLocationLink(editingProperty.gpsLocation);
      setCategory(editingProperty.category);
      setPropertyType(editingProperty.propertyType);
      setTitle(editingProperty.title || "");
      setDescription(editingProperty.description || "");
      setAddress(editingProperty.address || "");
      setCity(editingProperty.city || "");
      setLocation(editingProperty.location || "");
      setSubLocation(editingProperty.subLocation || "");
      setLandmark(editingProperty.landmark || "");
      setPincode(editingProperty.pincode || "");
      setPropertyState(editingProperty.proprtyState);
      setPropertyPrice(editingProperty.propertyPrice || "");
      setMaintanenceCharge(editingProperty.maintanenceCharge || "");
      setWidth(editingProperty.width || "");
      setLength(editingProperty.length || "");
      setTotalArea(editingProperty.totalArea || "");
      setWidthOfFacingRoad(editingProperty.widthOfFacingRoad || "");
      setBhk(editingProperty.bhk || "");
      setBathrooms(editingProperty.bathrooms || "");
      setFurnishType(editingProperty.furnishType || "");
      setCovered2WheelParking(editingProperty.covered2WheelParking || "");
      setCovered4WheelParking(editingProperty.covered4WheelParking || "");
      setOpen2WheelParking(editingProperty.open2WheelParking || "");
      setOpen4WheelParking(editingProperty.open4WheelParking || "");
      setBuiltupArea(editingProperty.builtupArea || "");
      setCarpetArea(editingProperty.carpetArea || "");
      setProcessionStatus(editingProperty.processingStatus || "");
      setLatitude(editingProperty.latitude || "");
      setLongitude(editingProperty.longitude || "");
      setFacing(editingProperty.facing || "");
      setSqftPrice(editingProperty.sqftPrice || "");
      // Update showSellDetails and showRentDetails based on editingProperty state
      setShowSellDetails(editingProperty.proprtyState === "Sell");
      setShowRentDetails(editingProperty.proprtyState === "Rent");
    }

    console.log(editingProperty);
  }, [editingProperty]);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Mapping over the selected files to create an array of objects containing file and URL
    const newImages = selectedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file)
    }));

    // Updating images state using functional form of setState
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  // const renderImagePreviews = () => {
  //   return selectedFiles.map((file, index) => (
  //     <img
  //       key={index}
  //       src={URL.createObjectURL(file)}
  //       alt={`Preview ${index + 1}`}
  //       className="preview-image"
  //     />
  //   ));
  // };
  const handleEditSubmit = async () => {
    try {
      const formData = new FormData();

      // Append fields to FormData object
      formData.append("id", editingProperty.id);
      formData.append("userId", editingProperty.userId);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("location", location);
      formData.append("subLocation", subLocation);
      formData.append("landmark", landmark);
      formData.append("pincode", pincode);
      formData.append("category", category);
      formData.append("propertyState", propertyState);
      formData.append("propertyType", propertyType);
      formData.append("processionStatus", processionStatus);
      formData.append("propertyPrice", propertyPrice);
      formData.append("maintanenceCharge", maintanenceCharge); // Corrected spelling
      formData.append("width", width);
      formData.append("length", length);
      formData.append("totalArea", TotalArea);
      formData.append("widthOfFacingRoad", widthOfFacingRoad);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("facing", Facing);
      // formData.append("constructionStatus", editingProperty.constructionStatus);
      formData.append("bhk", bhk);
      formData.append("bathrooms", bathrooms);
      formData.append("furnishType", furnishType);
      formData.append("covered2WheelParking", covered2WheelParking);
      formData.append("covered4WheelParking", covered4WheelParking);
      formData.append("open2WheelParking", open2WheelParking);
      formData.append("open4WheelParking", open4WheelParking);
      formData.append("builtupArea", builtupArea);
      formData.append("carpetArea", carpetArea);
      formData.append("status", activestatus);
      formData.append("sqftPrice", sqftPrice);
      formData.append("gpslocation", GpsLocationLink); // Corrected variable name

      // Append image files
      images.forEach((file, index) => {
        formData.append(`images`, file.file);
      });
      // const fileInput = document.getElementById("myFile");
      // const files = fileInput.files;
      // for (let i = 0; i < files.length; i++) {
      //   formData.append("images", files[i]);
      // }

      // Send the updated property object to the server for updating
      const response = await Instence.post(
        `/Property/UpdateListing?Id=${editingProperty.id}`,
        formData
      );

      if (response.data) {
        setEditOpen(false);
        fetchProperties();
        window.location.reload();
      }

      Swal.fire({
        title: "Success!",
        text: "Property updated successfully",
        icon: "success",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 3000, // Auto-close after 3 seconds
      });

      // Handle success response
      console.log("Property updated successfully:", response.data);
    } catch (error) {
      // Handle error
      console.error("Error updating property:", error);
    }
  };
  const handlepropertStateChange = (event) => {
    const selectedPropertyState = event.target.value;
    setPropertyState(selectedPropertyState);
    setShowSellDetails(selectedPropertyState === "Sell");
    setShowRentDetails(selectedPropertyState === "Rent");
    setEditingProperty((prevState) => ({
      ...prevState,
      propertyState: selectedPropertyState,
    }));
  };

  const handlePropertyTypeChange = (event) => {
    const selectedPropertyType = event.target.value;
    setPropertyType(selectedPropertyType);
    setEditingProperty((prevState) => ({
      ...prevState,
      propertyType: selectedPropertyType,
    }));
  };

// ********************* Data Mapping for Locations, Sub Locations ***************************
const [Data, setData] = useState([]);
const GetLocationsData = async () => {
  try {
    const response = await Instence.get("Location");
    if (response.status === 200) {
      setData(response.data);
      console.log("Fetched Locations Data", response.data);
    }
  } catch (error) {
    console.log(error);
  }
};

useEffect(() => {
  GetLocationsData();
}, []);

console.log("Filtered Data", Data);

const filteredAreas = city
  ? Data.find((item) => item.id === parseInt(city))?.areas.sort((a, b) => a.localeCompare(b)) || []
  : [];

console.log("Filtered Areas:", filteredAreas);



  // const filteredAreas = city
  //   ? Data.city.find((item) => item.name === city)?.areas || []
  //   : [];

  const [propdata, setPropData] = useState([]);
  const [likedProperty, setLikedProperty] = useState([]);

  const fetchLikedProperty = async () => {
    try {
      const response = await Instence.get(`/Property/LikedProp/${userId.id}`);
      setLikedProperty(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching liked properties:", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await Instence.get(`/Property/GetAllOnlyTrue`);
      setPropData(response.data);
      console.log("All Properties", response.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  useEffect(() => {
    fetchProperties();
    fetchLikedProperty();
  }, []);

  const [matchedData, setMachedData] = useState([]);

  console.log(matchedData)

  useEffect(() => {
    // Once both propdata and likedProperty are available, filter propdata based on likedProperty
    const matched = propdata.filter((prop) =>
      likedProperty.some((likedProp) => likedProp.propId === prop.id)
    );

    setMachedData(matched);
    console.log(matched);
  }, [propdata, likedProperty]);

  const handlelikedDelete = async (id) => {
    try {
      // Show SweetAlert confirmation dialog
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: `You are about to delete property with ID ${id}. This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Proceed with deletion if user clicks OK
          const response = await Instence.post(
            `/Property/DeleteLikedProp/${id}`
          );
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: `Property with ID ${id} deleted successfully.`,
            });
          }
          fetchProperties();
          fetchLikedProperty();
        }
      });
    } catch (error) {
      console.error(`Error deleting property with ID ${id}:`, error);
    }
  };

  // ********************************** Pagination Logic for Liked Properties Here *************************************************

  const [currentPageLiked, setCurrentPageLiked] = useState(1);
  const [propertiesPerPageLiked] = useState(2);
  // Logic to calculate current properties to display based on pagination
  const indexOfLastPropertyLiked = currentPageLiked * propertiesPerPageLiked;
  const indexOfFirstPropertyLiked =
    indexOfLastPropertyLiked - propertiesPerPageLiked;
  const currentPropertiesLiked = matchedData.slice(
    indexOfFirstPropertyLiked,
    indexOfLastPropertyLiked
  );
  // Logic to handle pagination page change
  const paginateLiked = (pageNumber) => setCurrentPageLiked(pageNumber);
  // Logic to generate pagination items
  const paginationItemsLiked = [];
  for (
    let number = Math.max(1, currentPage - 2);
    number <=
    Math.min(
      currentPageLiked + 2,
      Math.ceil(matchedData.length / propertiesPerPageLiked)
    );
    number++
  ) {
    paginationItemsLiked.push(
      <Pagination.Item
        key={number}
        active={number === currentPageLiked}
        onClick={() => paginateLiked(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  //! Add ellipsis to the pagination if necessary
  if (currentPageLiked > 3) {
    paginationItemsLiked.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
  }
  if (
    currentPageLiked <
    Math.ceil(matchedData.length / propertiesPerPageLiked) - 2
  ) {
    paginationItemsLiked.push(<Pagination.Ellipsis key="end-ellipsis" />);
  }



  return (
    <>
      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* PRODUCT TAB AREA START */}
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <div className="nav">
                          <a
                            className="active show"
                            data-bs-toggle="tab"
                            href="#ltn_tab_1_1"
                          >
                            Dashboard <i className="fas fa-home" />
                          </a>
                          <a data-bs-toggle="tab" href="#ltn_tab_1_2">
                            Profiles <i className="fas fa-user" />
                          </a>
                          {/* <a data-bs-toggle="tab" href="#ltn_tab_1_3">address <i className="fas fa-map-marker-alt" /></a> */}
                          {/* <a data-bs-toggle="tab" href="#ltn_tab_1_4">Account Details <i className="fas fa-user" /></a> */}
                          <a data-bs-toggle="tab" href="#ltn_tab_1_5">
                            My Properties <i className="fa-solid fa-list" />
                          </a>
                          <a data-bs-toggle="tab" href="#ltn_tab_1_6">
                            Favourite Properties{" "}
                            <i className="fa-solid fa-heart" />
                          </a>
                          <a data-bs-toggle="tab" href="#ltn_tab_1_7">
                            Add Property{" "}
                            <i className="fa-solid fa-map-location-dot" />
                          </a>
                          {/* <a data-bs-toggle="tab" href="#ltn_tab_1_8">Payments <i className="fa-solid fa-money-check-dollar" /></a> */}
                          {/* <a data-bs-toggle="tab" href="#ltn_tab_1_9">
                            Change Password <i className="fa-solid fa-lock" />
                          </a> */}
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handlelogout}
                          >
                            Logout <i className="fas fa-sign-out-alt" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="ltn_tab_1_1"
                        >
                          <div className="ltn__myaccount-tab-content-inner">
                            <p>
                              Hello <strong>{userId.firstName}</strong> (not{" "}
                              <strong>{userId.firstName}</strong>?{" "}
                              <small style={{ cursor: "pointer" }}>
                                <a onClick={handlelogout}>Log out</a>
                              </small>{" "}
                              )
                            </p>
                            <p>
                              From your account dashboard you can view your{" "}
                              <span>recent orders</span>, manage your{" "}
                              <span>shipping and billing addresses</span>, and{" "}
                              <span>
                                edit your password and account details
                              </span>
                              .
                            </p>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn_tab_1_2">
                          <div className="ltn__myaccount-tab-content-inner">
                            {/* comment-area */}
                            <div className="ltn__comment-area mb-50">
                              <div
                                className="ltn-author-introducing clearfix"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="author-img">
                                  {agentData.image ? (
                                    <img src={`https://way2landmark20250203151009.azurewebsites.net/uploads/${agentData.image}`} className="profile_images" alt="user" />
                                  ) : (
                                    <img src={
                                      publicUrl +
                                      "assets/img/blog/blank-profile.png"
                                    }
                                      alt="Author Image" />
                                  )}
                                  {/* <FcAddImage className='add_Image' onClick={handleImageUpload} /> */}
                                  {/* <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileInputChange}
                                    accept="image/*"
                                  /> */}
                                </div>
                                <div className="author-info">
                                  <h6>Agent of Property</h6>
                                  <h2>
                                    {agentData.firstName +
                                      " " +
                                      agentData.lastName}
                                  </h2>
                                  <div className="footer-address">
                                    <ul>
                                      <li>
                                        <div className="footer-address-info">
                                          <h6>
                                            Role: <span>{agentData.role}</span>
                                          </h6>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="footer-address-icon">
                                          <i className="icon-mail" />
                                        </div>
                                        <div className="footer-address-info">
                                          <p>
                                            <a href="mailto:example@example.com">
                                              {agentData.email}
                                            </a>
                                          </p>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <FaEdit className="EdituserIcon" onClick={() => setModel(true)} />
                              </div>
                              <ContactForm />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn_tab_1_5">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div className="ltn__my-properties-table table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">My Properties</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Date Added</th>
                                    <th scope="col">Actions</th>
                                    {/* <th scope="col">Delete</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {userdata && userdata.length > 0 ? (
                                    currentProperties.map((property, index) => {
                                      console.log(property);
                                      return (
                                        <tr key={index}>
                                          <td className="ltn__my-properties-img go-top">
                                            <div className="ltn__my-properties-img-div">
                                              <Link
                                                to={`/product-details/${property.id}`}
                                              >
                                                {property.propertyImages &&
                                                  property.propertyImages.length >
                                                  0 ? (
                                                  <img
                                                    src={`https://way2landmark20250203151009.azurewebsites.net/uploads/${property.propertyImages[0].imageName}`}
                                                    alt="#"
                                                  />
                                                ) : (
                                                  <img
                                                    src={dummyProperty}
                                                    alt="Alternative Image"
                                                  />
                                                )}
                                              </Link>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ltn__my-properties-info">
                                              <h6 className="mb-10 go-top">
                                                <Link
                                                  to={`/product-details/${property.id}`}
                                                >
                                                  {property.title}
                                                </Link>
                                              </h6>
                                              <small>
                                                {/* <i className="icon-placeholder" />{" "} */}
                                                {property.city}
                                              </small>
                                            </div>
                                          </td>
                                          <td>
                                            {new Date(
                                              property.createdAt
                                            ).toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })}
                                          </td>
                                          <td className="actions_col">
                                            <span
                                              onClick={() =>
                                                handleEdit(property.id)
                                              }
                                            >
                                              <BiEdit />
                                            </span>
                                            <span
                                              onClick={() => {
                                                handleDelete(property.id);
                                              }}
                                            >
                                              <i className="fa-solid fa-trash-can" />
                                            </span>
                                          </td>
                                          {/* <td></td> */}
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="5">
                                        No properties data available.
                                        {/* <SpinnerComponent /> */}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {/* **********************  Pagination  *********************************************** */}
                            <div className="ltn__pagination-area text-center">
                              <div className="pagination-container">
                                <Pagination>{paginationItems}</Pagination>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ***************************  Favourite Properties ****************************** */}

                        <div className="tab-pane fade" id="ltn_tab_1_6">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div className="ltn__my-properties-table table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col"> Property</th>
                                    <th scope="col">Title</th>

                                    <th scope="col">Date Added</th>
                                    <th scope="col">Delete</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentPropertiesLiked.length > 0 ? (
                                    currentPropertiesLiked.map(
                                      (property, index) => {
                                        console.log(property);
                                        return (
                                          <tr key={index}>
                                            <td className="ltn__my-properties-img go-top">
                                              <Link
                                                to={`/product-details/${property.id}`}
                                              >
                                                {property.propertyImages &&
                                                  property.propertyImages.length >
                                                  0 ? (
                                                  <img
                                                    src={`https://way2landmark20250203151009.azurewebsites.net/uploads/${property.propertyImages[0].imageName}`}
                                                    alt="#"
                                                  />
                                                ) : (
                                                  <img
                                                    src={dummyProperty}
                                                    alt="Alternative Image"
                                                  />
                                                )}
                                              </Link>
                                            </td>
                                            <td>
                                              <div className="ltn__my-properties-info">
                                                <h6 className="mb-10 go-top">
                                                  <Link to="/product-details">
                                                    {property.title}
                                                  </Link>
                                                </h6>
                                                <small>
                                                  <i className="icon-placeholder" />{" "}
                                                  {property.location}
                                                </small>
                                              </div>
                                            </td>
                                            <td>
                                              {new Date(
                                                property.createdAt
                                              ).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })}
                                            </td>
                                            <td>
                                              <span
                                                onClick={() => {
                                                  handlelikedDelete(property.id);
                                                }}
                                              >
                                                <i className="fa-solid fa-trash-can" />
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="5">
                                        No Liked data available.
                                        {/* <SpinnerComponent /> */}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="ltn__pagination-area text-center">
                              <div className="pagination-container">
                                <Pagination>{paginationItemsLiked}</Pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn_tab_1_7">
                          <AddListing />
                        </div>
                        {/* <div className="tab-pane fade" id="ltn_tab_1_8">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div className="row">
                              <div className="col-lg-12">
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PRODUCT TAB AREA END */}
            </div>
          </div>
        </div>
      </div>

      {/* *************************** Edit OffCanvas Start ***************************************** */}

      {isEditOpen && (
        <>
          <div className="depart-overlay"></div>
          <div
            className={`depart-edit-offcanvas-menu ${isEditOpen ? "open" : ""}`}
          >
            <div className="depart-offcanvas-header">
              <h2>Edit Property</h2>
              <button className="close-btn" onClick={toggleEdit}>
                &times;
              </button>
            </div>
            <div className="depart-edit-offcanvas-content">
              {/* <EditListing property={editingProperty} setProperty={setEditingProperty} /> */}

              <div className="ltn__appointment-area pb-120">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="ltn__appointment-inner">
                        <form action="#">
                          <h2>1. Description</h2>
                          <p>
                            <small>
                              These fields are mandatory: Title, Property Media
                            </small>
                          </p>
                          <h6>Property Description</h6>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  type="text"
                                  name="ltn__name"
                                  placeholder="*Title (mandatory)"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </div>
                              <div className="input-item input-item-textarea ltn__custom-icon">
                                <textarea
                                  name="ltn__message"
                                  placeholder="Description"
                                  defaultValue={""}
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <h6>Select Categories</h6>
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="input-item">
                                <select
                                  value={category}
                                  onChange={(e) => setCategory(e.target.value)}
                                  className="nice-select"
                                >
                                  <option disabled selected value>
                                    I want to
                                  </option>
                                  <option value="Residential">
                                    Residential
                                  </option>
                                  <option value="Commercial">Commercial</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="input-item">
                                <select
                                  className="nice-select"
                                  value={propertyState}
                                  // onChange={(e) => setPropertyState(e.target.value)}
                                  onChange={handlepropertStateChange}
                                >
                                  <option disabled selected value="">
                                    I want to
                                  </option>
                                  <option value="Sell">Sell</option>
                                  <option value="Rent">Rentals</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {showSellDetails && (
                            <>
                              <h4>Sell</h4>
                              <h6>Listing Details</h6>
                              <div className="row">
                                <div className="col-lg-6 col-md-6">
                                  <div className="input-item">
                                    <select
                                      className="nice-select"
                                      value={propertyType}
                                      onChange={handlePropertyTypeChange}
                                    >
                                      <option disabled value="">
                                        Property Type
                                      </option>
                                      <option value="Apartment">
                                        Apartment
                                      </option>
                                      <option value="Flat">Flat</option>
                                      <option value="Independent House">
                                        Independent House
                                      </option>
                                      <option value="Villa">Villa</option>
                                      <option value="Plot">Plot</option>
                                      <option value="Agricultural Land">
                                        Agricultural Land
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                {propertyType === "Apartment" ||
                                  propertyType === "Flat" ||
                                  propertyType === "Independent House" ||
                                  propertyType === "Villa" ? (
                                  <>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={processionStatus}
                                          onChange={(e) =>
                                            setProcessionStatus(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            select Property Status
                                          </option>
                                          <option value="ready to move">
                                            ready to move
                                          </option>
                                          <option value="Under Construction">
                                            Under Construction
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={bhk}
                                          onChange={(e) =>
                                            setBhk(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option value=""> Select BHK</option>
                                          <option value="1Rk">1RK</option>
                                          <option value="1BHK">1BHK</option>
                                          <option value="2BHK">2BHK</option>
                                          <option value="3BHK">3BHK</option>
                                          <option value="3+BHK">3+BHK</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={bathrooms}
                                          onChange={(e) =>
                                            setBathrooms(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            {" "}
                                            Select Bathrooms
                                          </option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="3+">3+</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={furnishType}
                                          onChange={(e) =>
                                            setFurnishType(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option disabled value="">
                                            {" "}
                                            Select Furnish Type
                                          </option>
                                          <option value="Furnished">
                                            Furnished
                                          </option>
                                          <option value="Semi-Furnished">
                                            Semi-Furnished
                                          </option>
                                          <option value="Unfurnished">
                                            Unfurnished
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          className="nice-select"
                                          value={Facing}
                                          onChange={(e) => setFacing(e.target.value)}
                                        >
                                          <option value="">Select Facing</option>
                                          <option value="East">East</option>
                                          <option value="West">West</option>
                                          <option value="South">South</option>
                                          <option value="North">North</option>
                                          <option value="North East">North East</option>
                                          <option value="South East">South East</option>
                                          <option value="North West">North West</option>
                                          <option value="South West">South West</option>
                                          <option value="East West">East West</option>
                                          <option value="North South">North South</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <h6>Covered Parking</h6>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={covered2WheelParking}
                                          onChange={(e) =>
                                            setCovered2WheelParking(
                                              e.target.value
                                            )
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            Select 2 Wheelers Parking
                                          </option>
                                          <option value="Not Available">
                                            Not Available
                                          </option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={covered4WheelParking}
                                          onChange={(e) =>
                                            setCovered4WheelParking(
                                              e.target.value
                                            )
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            Select 4 Wheelers Parking
                                          </option>
                                          <option value="Not Available">
                                            Not Available
                                          </option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                        </select>
                                      </div>
                                    </div>
                                    <h6>Open Parking</h6>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={open2WheelParking}
                                          onChange={(e) =>
                                            setOpen2WheelParking(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            Select 2 Wheelers Parking
                                          </option>
                                          <option value="Not Available">
                                            Not Available
                                          </option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={open4WheelParking}
                                          onChange={(e) =>
                                            setOpen4WheelParking(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            Select 4 Wheelers Parking
                                          </option>
                                          <option value="Not Available">
                                            Not Available
                                          </option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                        </select>
                                      </div>
                                    </div>
                                    <h6>Property Price</h6>
                                    <p>
                                      <small>
                                        *Add units for Property Price only in
                                        K/L/Cr
                                      </small>
                                    </p>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          value={propertyPrice}
                                          onChange={(e) =>
                                            setPropertyPrice(e.target.value)
                                          }
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Cost ₹ (*only numbers)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          value={maintanenceCharge}
                                          onChange={(e) =>
                                            setMaintanenceCharge(e.target.value)
                                          }
                                          name="ltn__name"
                                          placeholder="Maintenance Charges (per month)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Carpet Area"
                                          value={carpetArea}
                                          onChange={(e) =>
                                            setCarpetArea(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Built Up Area"
                                          value={builtupArea}
                                          onChange={(e) =>
                                            setBuiltupArea(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Price per units (Ex :- 2000/Sqft)"
                                          value={sqftPrice}
                                          onChange={(e) => {
                                            setSqftPrice(e.target.value);
                                          }}
                                        />

                                      </div>
                                    </div>
                                  </>
                                ) : propertyType === "Plot" ||
                                  propertyType === "Agricultural Land" ? (
                                  <>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          value={processionStatus}
                                          onChange={(e) =>
                                            setProcessionStatus(e.target.value)
                                          }
                                          className="nice-select"
                                        >
                                          <option value="">
                                            select Property Status
                                          </option>
                                          <option value="Immediate">
                                            Immediate
                                          </option>
                                          <option value="In Future">
                                            In Future
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          value={propertyPrice}
                                          onChange={(e) =>
                                            setPropertyPrice(e.target.value)
                                          }
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Cost ₹ (*only numbers)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          value={maintanenceCharge}
                                          onChange={(e) =>
                                            setMaintanenceCharge(e.target.value)
                                          }
                                          name="ltn__name"
                                          placeholder="Maintenance Charges (per month)"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Price per units (Ex :- 2000/Sqft)"
                                          value={sqftPrice}
                                          onChange={(e) => {
                                            setSqftPrice(e.target.value);
                                          }}
                                        />

                                      </div>
                                    </div>
                                    <h6>Plot Area</h6>
                                    <p>
                                      <small>
                                        *Add units for plot area only in
                                        sqyd/sqin/sqft/sqm
                                      </small>
                                    </p>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Width"
                                          value={width}
                                          onChange={(e) =>
                                            setWidth(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Length"
                                          value={length}
                                          onChange={(e) =>
                                            setLength(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
    {/* *********************** NEW Field Added Total Area ************************** */}

    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Total Area"
                                          value={TotalArea}
                                          onChange={(e) =>
                                            setTotalArea(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>






    {/* ******************************************************************************** */}
                                    <div className="col-md-6">
                                      <div className="input-item input-item-textarea ltn__custom-icon">
                                        <input
                                          type="text"
                                          name="ltn__name"
                                          placeholder="Width of facing Road (in ft)"
                                          value={widthOfFacingRoad}
                                          onChange={(e) =>
                                            setWidthOfFacingRoad(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="input-item">
                                        <select
                                          className="nice-select"
                                          value={Facing}
                                          onChange={(e) => setFacing(e.target.value)}
                                        >
                                          <option value="">Select Facing</option>
                                          <option value="East">East</option>
                                          <option value="West">West</option>
                                          <option value="South">South</option>
                                          <option value="North">North</option>
                                          <option value="North East">North East</option>
                                          <option value="South East">South East</option>
                                          <option value="North West">North West</option>
                                          <option value="South West">South West</option>
                                          <option value="East West">East West</option>
                                          <option value="North South">North South</option>
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          )}
                          {showRentDetails && (
                            <>
                              <h4>Rent</h4>
                              <h6>Listing Details</h6>
                              <p>
                                <small>
                                  *Add units for Property Price only in K/L/Cr
                                </small>
                              </p>
                              <p>
                                <small>
                                  *Add units for Property width , length and Area only in (sqft)/(sqm)/(sqyd)/(sqin)
                                </small>
                              </p>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={processionStatus}
                                      onChange={(e) =>
                                        setProcessionStatus(e.target.value)
                                      }
                                      className="nice-select"
                                    >
                                      <option value="">
                                        select Property Status
                                      </option>
                                      <option value="ready to move">
                                        ready to move
                                      </option>
                                      <option value="Under Construction">
                                        Under Construction
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={bhk}
                                      onChange={(e) =>
                                        setBhk(e.target.value)
                                      }
                                      className="nice-select"
                                    >
                                      <option value=""> Select BHK</option>
                                      <option value="1Rk">1RK</option>
                                      <option value="1BHK">1BHK</option>
                                      <option value="2BHK">2BHK</option>
                                      <option value="3BHK">3BHK</option>
                                      <option value="3+BHK">3+BHK</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={bathrooms}
                                      onChange={(e) =>
                                        setBathrooms(e.target.value)
                                      }
                                      className="nice-select"
                                    >
                                      <option value="">
                                        {" "}
                                        Select Bathrooms
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="3+">3+</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={furnishType}
                                      onChange={(e) =>
                                        setFurnishType(e.target.value)
                                      }
                                      className="nice-select"
                                    >
                                      <option disabled value="">
                                        {" "}
                                        Select Furnish Type
                                      </option>
                                      <option value="Furnished">
                                        Furnished
                                      </option>
                                      <option value="Semi-Furnished">
                                        Semi-Furnished
                                      </option>
                                      <option value="Unfurnished">
                                        Unfurnished
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6"></div>
                                <h6>Covered Parking</h6>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={covered2WheelParking}
                                      onChange={(e) =>
                                        setCovered2WheelParking(
                                          e.target.value
                                        )
                                      }
                                      className="nice-select"
                                    >
                                      <option value="">
                                        Select 2 Wheelers Parking
                                      </option>
                                      <option value="Not Available">
                                        Not Available
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={covered4WheelParking}
                                      onChange={(e) =>
                                        setCovered4WheelParking(
                                          e.target.value
                                        )
                                      }
                                      className="nice-select"
                                    >
                                      <option value="">
                                        Select 4 Wheelers Parking
                                      </option>
                                      <option value="Not Available">
                                        Not Available
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                                <h6>Open Parking</h6>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={open2WheelParking}
                                      onChange={(e) =>
                                        setOpen2WheelParking(e.target.value)
                                      }
                                      className="nice-select"
                                    >
                                      <option value="">
                                        Select 2 Wheelers Parking
                                      </option>
                                      <option value="Not Available">
                                        Not Available
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item">
                                    <select
                                      value={open4WheelParking}
                                      onChange={(e) =>
                                        setOpen4WheelParking(e.target.value)
                                      }
                                      className="nice-select"
                                    >
                                      <option value="">
                                        Select 4 Wheelers Parking
                                      </option>
                                      <option value="Not Available">
                                        Not Available
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </select>
                                  </div>
                                </div>
                                <h6>Property Price</h6>
                                <p>
                                  <small>
                                    *Add units for Property Price only in
                                    K/L/Cr
                                  </small>
                                </p>
                                <div className="col-md-6">
                                  <div className="input-item input-item-textarea ltn__custom-icon">
                                    <input
                                      value={propertyPrice}
                                      onChange={(e) =>
                                        setPropertyPrice(e.target.value)
                                      }
                                      type="text"
                                      name="ltn__name"
                                      placeholder="Cost ₹ (*only numbers)"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item input-item-textarea ltn__custom-icon">
                                    <input
                                      type="text"
                                      value={maintanenceCharge}
                                      onChange={(e) =>
                                        setMaintanenceCharge(e.target.value)
                                      }
                                      name="ltn__name"
                                      placeholder="Maintenance Charges (per month)"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item input-item-textarea ltn__custom-icon">
                                    <input
                                      type="text"
                                      name="ltn__name"
                                      placeholder="Carpet Area"
                                      value={carpetArea}
                                      onChange={(e) =>
                                        setCarpetArea(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item input-item-textarea ltn__custom-icon">
                                    <input
                                      type="text"
                                      name="ltn__name"
                                      placeholder="Built Up Area"
                                      value={builtupArea}
                                      onChange={(e) =>
                                        setBuiltupArea(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item ">
                                    <select
                                      className="nice-select"
                                      value={Facing}
                                      onChange={(e) => setFacing(e.target.value)}
                                    >
                                      <option value="">Select Facing</option>
                                      <option value="East">East</option>
                                      <option value="West">West</option>
                                      <option value="South">South</option>
                                      <option value="North">North</option>
                                      <option value="North East">North East</option>
                                      <option value="South East">South East</option>
                                      <option value="North West">North West</option>
                                      <option value="South West">South West</option>
                                      <option value="East West">East West</option>
                                      <option value="North South">North South</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-item input-item-textarea ">
                                    <input
                                      type="text"
                                      name="ltn__name"
                                      placeholder="Price per units (Ex :- 2000/Sqft)"
                                      value={sqftPrice}
                                      onChange={(e) => {
                                        setSqftPrice(e.target.value);
                                      }}
                                    />

                                  </div>
                                </div>

                              </div>
                            </>
                          )}
                          <h2>2. Media</h2>
                          <h6>Listing Media</h6>
                          <input id="file" type="file" multiple onChange={handleFileChange} />
                          <br />
                          <div className="image-container d-flex">
                            {images.map((image, index) => (
                              <div key={index} id="image-preview">
                                <img src={image.url} alt={`Preview ${index}`} />
                                <button className="rajesh" onClick={() => handleImageDelete(index)}>
                                  <FaTrashAlt />
                                </button>
                              </div>
                            ))}
                          </div>
                          {/* <input
                            type="file"
                            id="myFile"
                            name="filename"
                            className="btn theme-btn-3 mb-10 file_inputFile"
                            multiple
                            onChange={handleFileChange}
                          />
                          <br />
                          <div
                            id="image-preview"
                            style={{
                              display: "flex",
                              gap: "20px",
                              marginRight: "20px",
                              width: "70px",
                              height: "70px",
                            }}
                          >
                            {renderImagePreviews()}
                          </div> */}
                          <br />
                          <p>
                            <small>
                              * At least 1 image is required for a valid
                              submission.
                            </small>
                            <br />
                            <small>
                              * If Multiple Images select all Images at Once.
                            </small>
                            <br />
                            <small>
                              * Images might take longer to be processed.
                            </small>
                          </p>
                          <h2>3. Location</h2>
                          <h6>Listing Location</h6>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  type="text"
                                  name="ltn__name"
                                  placeholder="*Address"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-item-name ltn__custom-icon">
                                {/* <input
                        type="text"
                        name="ltn__name"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      /> */}
                                <select
                                  className="nice-select"
                                  onChange={(e) => setCity(e.target.value)}
                                  value={city}
                                >
                                  <option value="">Select City</option>
                                  {Data.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  type="text"
                                  name="ltn__name"
                                  placeholder="State to Location"
                                  value={location}
                                  onChange={(e) => setLocation(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-item-name ltn__custom-icon">
                                {/* <input
                        type="text"
                        name="ltn__name"
                        placeholder="Sub Location"
                        value={subLocation}
                        onChange={(e) => setSubLocation(e.target.value)}
                      /> */}
                                <select
                                  className="nice-select"
                                  value={subLocation}
                                  onChange={(e) =>
                                    setSubLocation(e.target.value)
                                  }
                                >
                                  <option value="">Sub Location</option>
                                  {filteredAreas.map((area, index) => {
                                    console.log(area);
                                    return (
                                      <option key={index} value={area}>
                                        {area}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  type="text"
                                  name="ltn__name"
                                  placeholder="Landmark"
                                  value={landmark}
                                  onChange={(e) => setLandmark(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  type="text"
                                  name="ltn__name"
                                  placeholder="Pincode"
                                  value={pincode}
                                  onChange={(e) => setPincode(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="property-details-google-map mb-60">
                                {GpsLocationLink ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: GpsLocationLink,
                                    }}
                                  />
                                ) : (
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3425303.089462905!2d76.8146699311646!3d20.527718750567314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1646634634544!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    style={{ border: "0" }}
                                    allowFullScreen
                                    loading="lazy"
                                  ></iframe>
                                )}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="input-item input-item-name ltn__custom-icon">
                                {/* {errors.GpsLocationLink && <div className="error-message">{errors.GpsLocationLink}</div>} */}
                                <input
                                  type="text"
                                  name="ltn__name"
                                  placeholder="Gps Location Link (for Google Maps)"
                                  value={GpsLocationLink}
                                  onChange={(e) =>
                                    setGpsLocationLink(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="alert alert-warning d-none"
                            role="alert"
                          >
                            Please note that the date and time you requested may
                            not be available. We will contact you to confirm
                            your actual appointment details.
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="depart-offcanvas-footer">
              <button className="btn-cancel" onClick={toggleEdit}>
                Cancel
              </button>
              <button onClick={handleEditSubmit} className="btn-save">
                Update
              </button>
            </div>
          </div>
        </>
      )}

      {ismodel && (
        <>
          <div className="depart-overlay"></div>
          <div
            className={`depart-edit-offcanvas-menu ${ismodel ? "open" : ""}`}
          >
            <div className="depart-offcanvas-header">
              <h2>Edit User Details</h2>
              <button className="close-btn" onClick={() => { setModel(false) }}>
                &times;
              </button>
            </div>
            <div className="depart-edit-offcanvas-content">
              <div class="custom-form-container">
                <div class="custom-row">
                  <div class="custom-col">
                    <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  </div>
                  <div class="custom-col">
                    <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                </div>
                <div class="custom-row">
                  <div class="custom-col">
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div class="custom-col">
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                </div>
                <div class="custom-row">
                  <div class="custom-col">
                    <legend style={{ fontSize: "16px" }}>Profile Pic</legend>
                    <input type="file" onChange={(e) => setProfilePic(e.target.files[0])} />
                  </div>
                </div>
              </div>
            </div>
            <div className="depart-offcanvas-footer">
              <button className="btn-cancel" onClick={() => { setModel(false) }}>
                Cancel
              </button>
              <button onClick={handlesaveProfile} className="btn-save">
                Update
              </button>
            </div>
          </div>
        </>
      )}



      i
    </>
  );
};

export default MyAccount;

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Instence from "../../utils/Instence";
import SlickSlider from "react-slick";
import dummyProperty from "../../Img/team/properties.jpg";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import axios from "axios";

const ProductSliderV4 = () => {
  const history = useHistory();
  const [likedItemIds, setLikedItemIds] = useState([]);

  useEffect(() => {
    const storedLikedItemIds = JSON.parse(sessionStorage.getItem('likedItemIds'));
    if (storedLikedItemIds) {
      setLikedItemIds(storedLikedItemIds);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('likedItemIds', JSON.stringify(likedItemIds));
  }, [likedItemIds]);

  const toggleLike = async (itemId) => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));

    if (!userData) {
      history.push('/login');
      scrollToTop();
      console.error('User data not found in session storage.');
      return;
    }

    const userId = userData.id;

    try {
      if (likedItemIds.includes(itemId)) {
        setLikedItemIds(likedItemIds.filter((id) => id !== itemId));
        await removePropertyLiked(userId, itemId);
      } else {
        setLikedItemIds([...likedItemIds, itemId]);
        await addPropertyLiked(userId, itemId);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const addPropertyLiked = async (userId, propId) => {
    try {
      const response = await Instence.post(
        'Property/PropertyLiked',
        { userId, propId },
      );
      console.log('Property liked successfully:', response.data);
    } catch (error) {
      console.error('Error liking property:', error);
      throw error;
    }
  };

  const removePropertyLiked = async (userId, propId) => {
    try {
      const response = await axios.post(
        `Property/DeleteLikedProp/${propId}`,
      );
      console.log('Property unliked successfully:', response.data);
    } catch (error) {
      console.error('Error unliking property:', error);
      throw error;
    }
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  const [propdata, setPropData] = useState([]);

  const fetchProperties = async () => {
    try {
      const response = await Instence.get(`/Property/GetAllOnlyTrue`);
      setPropData(response.data);
      console.log("All Properties", response.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          autoplaySpeed: 7000,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 7000,
        },
      },
    ],
  };

  const customSliderStyles = `
    .slick-prev,
    .slick-next {
        color: #000 !important;
        font-size: 24px;
    }

    .slick-prev {
        left: -30px;
    }

    .slick-next {
        right: -30px;
    }
  `;

  const parseDimension = (dimension) => {
    const match = dimension.match(/^(\d+(\.\d+)?)(\s*(sqft|sqyd|sqm|sqin))$/);
    if (match) {
      return { value: parseFloat(match[1]), unit: match[4] };
    }
    return null;
  };

  const calculateArea = (width, length) => {
    const parsedWidth = parseDimension(width);
    const parsedLength = parseDimension(length);

    if (parsedWidth && parsedLength && parsedWidth.unit === parsedLength.unit) {
      const area = parsedWidth.value * parsedLength.value;
      return `${area.toFixed(2)} ${parsedWidth.unit}`;
    }
    return "N/A"; // Or handle unit conversion if needed
  };


     // Scroll to top function
     const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

  return (
    <div>
      <style>{customSliderStyles}</style>
      <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Properties
                </h6>
                <h1 className="section-title">Latest Listings</h1>
              </div>
            </div>
          </div>
          <SlickSlider
            {...sliderSettings}
            className="row ltn__product-slider-item-three-active slick-arrow-1"
          >
            {propdata.map((property, index) => {
              const area = property.propertyType === "Plot" || property.propertyType === "Agricultural Land"
                ? calculateArea(property.width, property.length)
                : null;

              return (
                <div className="col-xl-4 col-sm-6 col-12" key={index}>
                  <div className="ltn__product-item ltn__product-item-4 text-center---">
                    <div className="product-img go-top">
                      <Link to={`/product-details/${property.id}`}>
                        {property.propertyImages && property.propertyImages.length > 0 ? (
                          <img
                            src={`https://way2landmark20250203151009.azurewebsites.net/uploads/${property.propertyImages[0].imageName}`}
                            alt="#"
                          />
                        ) : (
                          <img src={dummyProperty} alt="Alternative Image" />
                        )}
                      </Link>
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badge bg-green">
                            {property.proprtyState}{""} {property.propertyType}
                          </li>
                        </ul>
                      </div>
                      <div className="product-img-location-gallery">
                        <div className="product-img-location">
                          <ul>
                            <li>
                              <Link>
                                <i className="flaticon-pin" /> {property.cityName},{" "}
                                {property.location}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-info">
                      <div className="d-flex justify-content-between">
                        {property.sqftPrice && (
                          <div className="product-price">
                            <span>{property.sqftPrice.replace(' ', '/')}</span>
                          </div>
                        )}
                        <div className="product-price">
                          <span>₹{property.propertyPrice}</span>
                        </div>
                      </div>
                      <h2 className="product-title go-top">
                        <Link to={`/product-details/${property.id}`}>
                          {property.title}
                        </Link>
                      </h2>
                      <div className="product-facing">
                        <div>
                          <span style={{ color: "#198754", fontWeight: "bold", fontSize: "18px" }}>
                            {property.facing}
                          </span> Facing
                          {property.totalArea ? (
                            <span style={{ display: "block" }}>
                              Total Area: <span style={{ color: "#198754", fontSize: "16px" }}>{property.totalArea}</span>
                            </span>
                          ) : (
                            <span style={{ display: "block" }}>
                              Total Area: <span style={{ color: "#198754", fontSize: "16px" }}>{area}</span>
                            </span>
                          )
                        }
                        </div>
                      </div>
                      <div className="product-description">
                        <p>{property.description}</p>
                      </div>
                      {(
                        property.propertyType === "Apartment" ||
                        property.propertyType === "Flat" ||
                        property.propertyType === "Independent House" ||
                        property.propertyType === "Villa"
                      ) && (
                          <ul className="ltn__list-item-2 ltn__list-item-2-before">
                            <li>
                              <span>
                                {property.bhk} <i className="flaticon-bed" />
                              </span>
                              Bedrooms
                            </li>
                            <li>
                              <span>
                                {property.bathrooms} <i className="flaticon-clean" />
                              </span>
                              Bathrooms
                            </li>
                          </ul>
                        )}
                      {(
                        property.propertyType === "Plot" ||
                        property.propertyType === "Agricultural Land"
                      ) && (
                          <ul className="ltn__list-item-2 ltn__list-item-2-before">
                            <li>
                              <span>
                                {property.width}
                              </span>
                              Width
                            </li>
                            <li>
                              <span>
                                {property.length}
                              </span>
                              Length
                            </li>
                          </ul>
                        )}
                    </div>
                    <div className="product-info-bottom d-flex justify-content-between align-items-center">
                      <div className="contact-details">
                        <h6>
                          <BsFillTelephoneForwardFill className="phone-ring" />{" "}
                          +91 6303816633
                        </h6>
                      </div>
                      <div className="product-hover-action">
                        <ul>
                          <li>
                            <a
                              onClick={() => toggleLike(property.id)}
                              title="Wishlist"
                            >
                              {likedItemIds.includes(property.id) ? (
                                <FaHeart className="red-heart" />
                              ) : (
                                <i className="flaticon-heart-1" />
                              )}
                            </a>
                          </li>
                          <li>
                            <span className="go-top">
                              <Link
                                to={`/product-details/${property.id}`}
                                title="Product Details"
                              >
                                <i className="flaticon-add" />
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
};

export default ProductSliderV4;

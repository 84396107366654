import axios from "axios";


const Instence = axios.create({
  baseURL: "https://way2landmark20250203151009.azurewebsites.net/api/",
  // baseURL: "http://192.168.0.195:809/api/",
  headers: { Accept: "application/json" },
});

export default Instence;

// export const imageURL = "http://192.168.0.195:809/"

export const imageURL = "https://way2landmark20250203151009.azurewebsites.net"

// https://way2landmark20250203151009.azurewebsites.net/

// https://way2landmark.drmarins.cloud/api/User